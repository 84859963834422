export default [
  {
    path: '/offer/:id?',
    name: 'app-offer',
    component: () => import('@/views/pages/offer/offer.vue'),
    meta: {
      pageTitle: 'Uw voorstel',
    },
  },
  {
    path: '/',
    name: 'home-redirect',
    beforeEnter() {
      window.location.href = 'https://websiteinnovators.nl/webshops/'
    },
  },
]
